import React, { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import { Box, Grid } from "@mui/material";
import Section from "../../components/section/section.component";
import Footer from "../../components/footer/footer.component";
import axios from "axios";
import MediumCard from "../../components/medium-card/medium-card.component";
import { getUserInfo } from "../../services/userInfo.service";
import Filters from "../../components/filters/filters.component";
import moment from "moment-timezone";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";

const Sales = () => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  // eslint-disable-next-line
  const [period, setPeriod] = useState("w");
  const [bigCardSubtitle, setBigCardSubtitle] = useState("");
  const [sales, setSales] = useState([]);
  const [date, setDate] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
    productCategories: "default", //label: "Categorías"
  });

  function getMetricsKeys(object) {
    // Check if the object has the "metrics" property
    if (object && typeof object === "object" && object.metrics) {
      // Get the keys of the "metrics" property
      return Object.keys(object.metrics);
    } else {
      // Return an empty array if the "metrics" property is not present or invalid
      return [];
    }
  }

  const fetchSales = async () => {
    try {
      if (userInfo) {
        if (date) {
          const { data } = await axios.get(
            `https://data.myma.space/api/sales?clientId=${userInfo.customer_id}&group=${filters.groups}&period=${period}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}&storeId=${filters.machines}&date=${date}`
          );
          setSales(data);
          setProductCategories(getMetricsKeys(data));
        }
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };
  useEffect(
    () => {
      if (loginToken) {
        if (axios.defaults.headers.common["Authorization"]) {
          fetchSales();
        } else {
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchSales();
            }
          }, 100);
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken, date]
  );
  //Initialize date
  useEffect(
    () => {
      if (sales) {
        setDate(
          moment(sales.date)
            .add(6, "hours")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        );
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <PullToRefreshContainer onRefresh={fetchSales}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mi</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Lista de Compras</div>
          </div>
        }
        avatar="FB"
        longTitle
        disableCart
      />
      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Filters
          disableFirstMachineSelected
          setBigCardSubtitle={setBigCardSubtitle}
          filters={filters}
          setFilters={setFilters}
          productCategories={productCategories}
        />
        <Grid container sx={{ marginBlockStart: "0rem" }}>
          <Grid item xs={12}>
            <Section title="LISTOS P/ COMER: &nbsp; Ventas → Nuevas Compras" />
            <Grid sx={{ marginBlockStart: "0.5rem" }} item xs={12}>
              <MediumCard
                variant="f"
                data={{
                  list:
                    filters.productCategories === "default"
                      ? sales?.metrics
                        ? Object.values(sales?.metrics).flatMap((category) =>
                            category.map((item) => ({
                              total: item.total,
                              category: item.category,
                              name: item.name,
                              sku: item.sku,
                            }))
                          )
                        : []
                      : sales?.metrics[filters?.productCategories] || [],
                  date: date,
                  setDate,
                  variant: "neutral",
                  filters: filters,
                  setFilters: setFilters,
                  productCategories: productCategories,
                  position:
                    filters.groups !== "default"
                      ? filters.groups
                      : filters.clusters !== "default"
                      ? filters.clusters
                      : filters.locations !== "default"
                      ? filters.locations
                      : filters.categories !== "default"
                      ? filters.categories
                      : filters.machines !== "default"
                      ? bigCardSubtitle
                      : "MDE", // Valor por defecto si todas las opciones son "default"
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer activeIconID={null} />
    </PullToRefreshContainer>
  );
};

export default Sales;
