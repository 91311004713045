import { Grid } from "@mui/material";
import React from "react";
import IncrementalUnits from "./incremental-units.component";
import IncrementalProfit from "./incremental-profit.component";

const MetricsImpact = ({
  totalIncrementalUnits,
  totalIncrementalProfit,
  incrementalUnitsPercentage,
  incrementalProfitPercentage,
  previousAndActualPeriodMetrics,
}) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        gap: "0.5rem",
        paddingInline: "0.5rem",
      }}
    >
      <IncrementalUnits
        incrementalUnitsPercentage={incrementalUnitsPercentage}
        previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
        totalIncrementalUnits={totalIncrementalUnits}
      />
      <IncrementalProfit
        incrementalProfitPercentage={incrementalProfitPercentage}
        previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
        totalIncrementalProfit={totalIncrementalProfit}
      />
    </Grid>
  );
};

export default MetricsImpact;
