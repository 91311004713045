import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ExpiredSessionDialog = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userInfo");
    setOpen(false);
    navigate("/login");
  };

  return (
    <Dialog open={open} onClose={handleLogout}>
      <DialogTitle sx={{ color: "#663b73" }}>Sesión Expirada</DialogTitle>
      <DialogContent>
        <p>Por favor, vuelva a iniciar sesión para continuar.</p>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="purple">
          Cancelar
        </Button> */}
        <Button onClick={handleLogout} color="purple">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpiredSessionDialog;
