import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
// import { ReactComponent as ArrowSmallRed } from "../../../assets/images/arrow-red-small.svg";
// import { ReactComponent as AlertsIcon } from "../../../assets/images/notifications-icon.svg";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CloseIcon from "@mui/icons-material/Close";

const alertsCategoryEquivalency = {
  info: {
    title: "Información",
    color: "#446BB2",
    icon: <InfoRoundedIcon style={{ height: "80%", fill: "#446BB2" }} />,
  },
  warning: {
    title: "Advertencia",
    color: "#FFD702",
    icon: (
      <WarningAmberRoundedIcon style={{ height: "80%", fill: "#FFD702" }} />
    ),
  },
  error: {
    title: "Error",
    color: "#FA0909",
    icon: <ErrorRoundedIcon style={{ height: "80%", fill: "#FA0909" }} />,
  },
};

const formatDatesRange = (dateString) => {
  const formatDate = (date) => {
    if (!date) return "-/-";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year.slice(2)}`;
  };
  const [datePart] = dateString.split("T");
  const formattedDate = formatDate(datePart);

  return formattedDate;
};

function cleanString(text) {
  var pattern = /[^a-zA-Z0-9\s]/g;
  var cleanedText = text.replace(pattern, "");
  return cleanedText;
}

const AlertSmallCard = ({
  setConfirmDeleteDialogOpen,
  setAlertIdToDelete,
  category,
  dStore,
  dateCreated,
  id,
  message,
}) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "1rem",
          marginBlock: "0.5rem",
          position: "relative",
          display: "flex",
          width: "350px",
          height: "8rem",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            position: "relative",
            display: "flex",
            width: "100%",
            height: "100%",
            // border: "2px solid #727272",
            borderRadius: "1rem",
            paddingInlineEnd: "0.5rem",
            paddingInlineStart: "0.2rem",
            paddingBlock: "0.5rem",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <CloseIcon
            onClick={() => {
              setConfirmDeleteDialogOpen(true);
              setAlertIdToDelete(id);
            }}
            sx={{
              position: "absolute",
              right: "0.4rem",
              top: "0.4rem",
              width: "1.2rem",
              height: "1.2rem",
              fill: "#663B73",
              // outline: "1px solid #663B73",
              borderRadius: "50%",
              backgroundColor: "#F8F8F8",
            }}
          />
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                maxHeight: 1 / 3,
                width: "100%",
              }}
            >
              {/* <AlertsIcon style={{ height: "80%", fill: "#FF0707" }} /> */}
              {alertsCategoryEquivalency[category].icon}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: 2 / 3,
                width: "100%",
              }}
            >
              {/* <ArrowSmallRed /> */}
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={11}>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", maxHeight: 1.5 / 5 }}
              xs={12}
            >
              <Typography
                sx={{
                  color: alertsCategoryEquivalency[category].color,
                  fontWeight: "bold",
                  display: "flex",
                }}
              >
                {alertsCategoryEquivalency[category].title}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  display: "flex",
                  marginInlineStart: "1rem",
                  fontSize: "0.85rem",
                }}
              >
                {formatDatesRange(dateCreated)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "start",
                maxHeight: 2.5 / 5,
                overflow: "hidden",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.85rem",
                  fontWeight: "bold",
                  display: "flex",
                  lineHeight: "0.85rem",
                }}
              >
                {message}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                maxHeight: 1 / 5,
                overflow: "hidden",
              }}
              xs={12}
            >
              <Typography
                sx={{
                  fontSize: "0.65rem",
                  fontWeight: "bold",
                  overflow: "hidden",
                  lineHeight: "0.65rem",
                }}
              >
                {dStore ? (
                  <span>
                    {/* Máquina: {dStore.storeClusterId} {dStore.storeId?.slice(1)} */}
                    Máquina: {cleanString(dStore?.alias)}
                  </span>
                ) : (
                  <span>Notificación Global</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AlertSmallCard;
