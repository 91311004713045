import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as ArrowSmallRed } from "../../../assets/images/arrow-red-small.svg";
import { ReactComponent as ArrowSmallGreen } from "../../../assets/images/arrow-green-small.svg";
import { ReactComponent as ExperimentsIcon } from "../../../assets/images/experiments-icon.svg";
import { experimentsEquivalency } from "../experimentsEquivalency";

const RunningExperimentsCard = ({
  status,
  experimentType,
  product,
  store,
  period,
  problem,
  id,
  header,
}) => {
  const formatNumber = (num) => {
    return `[${num.toString().padStart(4, "0")}]`;
  };

  const formatDatesRange = (dateFrom, dateTo) => {
    const formatDate = (dateString) => {
      if (!dateString) return "-/-";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      return `${day}/${month}`;
    };

    const formattedFrom = formatDate(dateFrom);
    const formattedTo = formatDate(dateTo);

    return `Inicio: ${formattedFrom} Fin: ${formattedTo}.`;
  };

  // const capitalizeWords = (str) => {
  //   const lowerCaseStr = str.toLowerCase();
  //   const words = lowerCaseStr.split(" ");
  //   const capitalizedWords = words.map((word) => {
  //     return word.charAt(0).toUpperCase() + word.slice(1);
  //   });
  //   return capitalizedWords.join(" ");
  // };

  const calculateVariation = (newVal, oldVal) => {
    return Math.round(((newVal - oldVal) / oldVal) * 100);
  };

  function cleanString(text) {
    var pattern = /\*/g;
    var cleanedText = text.replace(pattern, "");
    return cleanedText;
  }

  const [isPositiveVariant, setIsPositiveVariant] = useState(true);

  useEffect(() => {
    setIsPositiveVariant(status?.unitsSold >= problem?.unitsSold);
  }, [status, problem]);

  return (
    <Grid container>
      <Grid
        item
        sx={{
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          display: "flex",
          width: "350px",
          // height:
          //   experimentType?.name === "promo_precio_unitario" ? "7rem" : "6rem",
        }}
        xs={12}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            // border: "2px solid #727272",
            borderRadius: "1rem",
            paddingInlineEnd: "0.5rem",
            paddingInlineStart: "0.2rem",
            paddingBlock: "0.5rem",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={1}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: 1 / 3,
                width: "100%",
              }}
            >
              <ExperimentsIcon style={{ height: "100%" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: 2 / 3,
                width: "100%",
              }}
            >
              {isPositiveVariant ? <ArrowSmallGreen /> : <ArrowSmallRed />}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              // height:
              //   experimentType?.name !== "reemplazo_producto"
              //     ? "6.5rem"
              //     : "5.5rem",
              gap: "0.5rem",
              marginBlock: "auto",
              marginBlockStart: "0.5rem",
            }}
            xs={6}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", minHeight: 1 / 3 }}
              xs={12}
            >
              <Typography
                sx={{
                  color: isPositiveVariant ? "#00A87C" : "#FF0707",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  lineHeight: "0.9rem",
                }}
              >
                {experimentsEquivalency[experimentType?.name]?.fullLabel}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", height: 1.5 / 3 }}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  lineHeight: "0.85rem",
                  // fontWeight: "bold",
                  overflow: "hidden",
                }}
              >
                {experimentsEquivalency[
                  experimentType?.name
                ]?.runningDescription({
                  productName: product?.name,
                  newProductName: experimentType?.newProduct?.name,
                  discountPercentage: experimentType?.discountPercent,
                  price: experimentType?.price?.toLocaleString("es-ES"),
                  newPrice: experimentType?.newPrice?.toLocaleString("es-ES"),
                  firstProduct: experimentType?.firstProduct?.name,
                  secondProduct: experimentType?.secondProduct?.name,
                  comboPrice: status?.avgPrice,
                })}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", height: 0.5 / 3 }}
              xs={12}
            >
              <Typography
                sx={{
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                  overflow: "hidden",
                  lineHeight: "0.65rem",
                }}
              >
                M:{cleanString(store?.alias)}
                <br />
                {formatDatesRange(period?.start, period?.end)}
                <span>
                  <br />
                  EXP: {formatNumber(id)}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={5 / 3}
          >
            <Grid
              item
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={12}
            >
              <Typography>{problem?.unitsSold}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={5 / 3}
          >
            <Grid
              item
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={12}
            >
              <Typography>{status?.unitsSold}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={5 / 3}
          >
            <Grid
              item
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: isPositiveVariant ? "#00A87C" : "#FF0707",
              }}
              xs={12}
            >
              <Typography>
                {calculateVariation(status?.unitsSold, problem?.unitsSold)}%
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RunningExperimentsCard;
