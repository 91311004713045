import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import customSelectStyles from "./customSelectStyles";

export const CustomSelect = ({
  name,
  value,
  onChange,
  items,
  label,
  isMachines,
  disabled,
  multiple,
}) => (
  <Select
    name={name}
    IconComponent={() => (
      <KeyboardArrowDownIcon sx={{ fontSize: "0.7rem", color: "#999" }} />
    )}
    MenuProps={{ sx: { width: "auto" } }}
    sx={customSelectStyles(isMachines)}
    value={value}
    onChange={onChange}
    disabled={disabled}
    multiple={multiple}
  >
    <MenuItem
      sx={{
        fontSize: "1rem",
        justifyContent: "center",
        color: "#663B73",
      }}
      value="default"
    >
      {label}
    </MenuItem>
    {items?.map((element, i) => {
      if (element !== "default") {
        return (
          <MenuItem
            sx={{
              display: isMachines ? "flex" : "flex",
              justifyContent: "center",
            }}
            key={i}
            value={isMachines ? element.id : element}
          >
            {isMachines ? element.alias : element}
          </MenuItem>
        );
      }
      return null;
    })}
  </Select>
);
