import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as ExperimentsIcon } from "../../../../assets/images/experiments-icon.svg";
// import { ReactComponent as VendingMachineIcon } from "../../../../assets/images/vending-icon.svg";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { experimentsEquivalency } from "../../experimentsEquivalency";

const MiddleSectionAction = ({ experimentType, period, product, status }) => {
  // const capitalizeWords = (str) => {
  //   const lowerCaseStr = str.toLowerCase();
  //   const words = lowerCaseStr.split(" ");
  //   const capitalizedWords = words.map((word) => {
  //     return word.charAt(0).toUpperCase() + word.slice(1);
  //   });
  //   return capitalizedWords.join(" ");
  // };

  const formatDatesRange = (dateFrom, dateTo) => {
    const formatDate = (dateString) => {
      if (!dateString) return "-/-";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      return `${day}/${month}`;
    };

    const formattedFrom = formatDate(dateFrom);
    const formattedTo = formatDate(dateTo);

    return `Inicio: ${formattedFrom} Fin: ${formattedTo}.`;
  };
  return (
    <>
      {/* mid section  */}

      <Grid item sx={{ height: 3 / 8, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <ExperimentsIcon style={{ width: "1.3rem" }} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: "0.2rem",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.68rem",
              lineHeight: "0.7rem",
            }}
          >
            {experimentsEquivalency[experimentType?.name]?.actionDescription({
              productName: product?.name,
              productIdealStock: product?.idealStock,
              newProductName: experimentType?.newProduct?.name,
              newProductIdealStock: experimentType?.newProduct?.idealStock,
              discountPercentage: experimentType?.discountPercent,
              price: experimentType?.price,
              newPrice: experimentType?.newPrice,
              firstProduct: experimentType?.firstProduct?.name,
              secondProduct: experimentType?.secondProduct?.name,
              comboPrice: status?.avgPrice,
            })}
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid item sx={{ height: 1 / 4, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <VendingMachineIcon
            style={{ width: "1.3rem", fill: "#000", height: "1.3rem" }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: "0.2rem",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
            }}
          >
            Ubicacion: Maquina Lanz Com 01 , Espacio 1026.
          </Typography>
        </Grid>
      </Grid> */}

      <Grid item sx={{ height: 3 / 8, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <CalendarMonthOutlinedIcon fontSize="medium" />
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center" }} item xs={11}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              marginInlineStart: "0.2rem",
            }}
          >
            {formatDatesRange(period?.start, period?.end)}
          </Typography>
        </Grid>
      </Grid>
      {/* mid section end  */}
    </>
  );
};

export default MiddleSectionAction;
