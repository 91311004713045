import React from "react";
import { Grid, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

const EmptyAlerts = () => {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          marginBlockStart: "2rem",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            height: "50%",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: "center", color: "#683d76" }}
          >
            <span>No tienes notificaciones en este momento.</span>
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              Mantente atento para recibir actualizaciones sobre eventos
              importantes.
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "50%",
            marginBlockStart: "2rem",
          }}
        >
          <NotificationsActiveOutlinedIcon
            sx={{ fontSize: "10rem" }}
            style={{ fill: "#cccccc" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EmptyAlerts;
