// eslint-disable-next-line
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/header.component";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import Filters from "../../components/filters/filters.component";

import Section from "../../components/section/section.component";
import Footer from "../../components/footer/footer.component";
import AlertSmallCard from "../../components/alerts/alerts-small-card/alerts-small-card.component";
import axios from "axios";
import EmptyAlerts from "./emptyalerts.component";
import { getUserInfo } from "../../services/userInfo.service";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";

const alertsSourceEquivalency = {
  clima: "Notificaciones por Clima",
  competencia: "Notificaciones por Competencia",
  costos: "Notificaciones por Costos",
  eventos: "Notificaciones por Eventos",
  mercado: "Notificaciones por Mercado",
  rotacion: "Notificaciones por Rotación",
};

const Alerts = () => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  // eslint-disable-next-line
  const [period, setPeriod] = useState("w");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [alertIdToDelete, setAlertIdToDelete] = useState(null);
  const [sourceToDelete, setSourceToDelete] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });
  // const sliderRef = useRef();

  const fetchAlerts = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `https://data.myma.space/api/notifications?clientId=${userInfo.customer_id}&group=${filters.groups}&period=${period}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}&storeId=${filters.machines}`
        );

        // Filter and group notifications by source property, excluding archived alerts.
        const groupedAlerts = data.reduce((grouped, alert) => {
          const { source, archived } = alert;
          if (!archived) {
            if (!grouped[source]) {
              grouped[source] = [];
            }
            grouped[source].push(alert);
          }
          return grouped;
        }, {});

        setAlerts(groupedAlerts);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(
    () => {
      if (loginToken) {
        if (axios.defaults.headers.common["Authorization"]) {
          fetchAlerts();
        } else {
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchAlerts();
            }
          }, 100);
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  const handleDeleteAlert = (alertId) => {
    const apiUrl = `https://data.myma.space/api/notifications/${alertId}/archive?clientId=${userInfo.customer_id}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (userInfo) {
      axios
        .post(apiUrl, {}, config)
        .then((response) => {
          // console.log("Alert archived successfully:", response.data);
          setConfirmDeleteDialogOpen(false);
          fetchAlerts();
        })
        .catch((error) => {
          // console.error("Error archieving alert:", error);
        });
    }
  };

  // const sliderSettings = {
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1.06,
  //   slidesToScroll: 1,
  // };

  return (
    <PullToRefreshContainer onRefresh={fetchAlerts}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Notificaciones</div>
          </div>
        }
        avatar="FB"
        longTitle
      />
      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={() => setConfirmDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirmar eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas eliminar ésta notificación?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDeleteDialogOpen(false)}
              color="purple"
            >
              Cancelar
            </Button>
            <Button
              onClick={async () => {
                handleDeleteAlert(alertIdToDelete, sourceToDelete);
              }}
              color="purple"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Filters filters={filters} setFilters={setFilters} />
        <Grid container sx={{ marginBlockStart: "0rem" }}>
          <Grid item xs={12}>
            {/* Check if there are any alerts available */}
            {Object.values(alerts).every(
              (alertsArray) => alertsArray.length === 0
            ) ? (
              <EmptyAlerts />
            ) : (
              // If there are alerts, map them according to the order specified in alertsSourceEquivalency
              Object.entries(alertsSourceEquivalency).map(([source, title]) => (
                <div key={source}>
                  {/* Check if there are alerts available for this source */}
                  {alerts[source] && alerts[source].length > 0 && (
                    <>
                      <Section title={title} />

                      {/* Map the alerts for this source */}
                      <Grid sx={{ marginBlockEnd: "0.5rem" }} item xs={12}>
                        {/* <Slider
                          key={sliderRef.current}
                          {...sliderSettings}
                          ref={sliderRef}
                        > */}
                        {alerts[source]
                          .slice()
                          .reverse()
                          .map((alert) => {
                            if (!alert.archived) {
                              return (
                                <AlertSmallCard
                                  key={alert.id}
                                  setConfirmDeleteDialogOpen={
                                    setConfirmDeleteDialogOpen
                                  }
                                  setSourceToDelete={setSourceToDelete}
                                  setAlertIdToDelete={setAlertIdToDelete}
                                  {...alert}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        {/* </Slider> */}
                      </Grid>
                    </>
                  )}
                </div>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
      <Footer activeIconID={3} />
    </PullToRefreshContainer>
  );
};

export default Alerts;
