import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk(
  "loginToken/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "https://data.myma.space/api/auth/login",
        {
          username,
          password,
        }
      );
      return data.access_token;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
