import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import numeral from "numeral";

const IncrementalUnits = ({
  totalIncrementalUnits,
  incrementalUnitsPercentage,
  previousAndActualPeriodMetrics,
}) => {
  return (
    <Grid item>
      <Paper
        elevation={3}
        sx={{
          borderRadius: "1rem",
          padding: "0.6rem 0.8rem",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
            xs={12}
          >
            <Typography
              sx={{
                fontSize: "0.9rem",
                textAlign: "start",
                lineHeight: "0.9rem",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#674EA7" }}>
                Unidades
              </span>{" "}
              Incrementales
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBlock: "0.5rem",
            }}
          >
            <Typography>
              <span
                style={{
                  fontSize: "2rem",
                  lineHeight: "2rem",
                  fontWeight: "bold",
                  color: "#663b73",
                }}
              >
                {totalIncrementalUnits >= 0 ? "+" : "-"}
                {numeral(totalIncrementalUnits).format(
                  totalIncrementalUnits >= 10000
                    ? totalIncrementalUnits % 1 === 0
                      ? "0a"
                      : "0.[0]a"
                    : null
                )}
              </span>
              <span style={{ fontSize: "1.2rem", lineHeight: "1.2rem" }}>
                u
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#663b73",
                borderRadius: "2px",
                color: "#fff",
                fontSize: "0.7rem",
                lineHeight: "0.7rem",
                padding: "2px",
                fontWeight: "bold",
              }}
            >
              {incrementalUnitsPercentage >= 0 ? "+" : "-"}
              {numeral(incrementalUnitsPercentage).format(
                incrementalUnitsPercentage >= 10000
                  ? incrementalUnitsPercentage % 1 === 0
                    ? "0a"
                    : "0.[0]a"
                  : null
              )}
              %
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              width: "100%",
              marginBlockStart: "0.8rem",
              //   backgroundColor: "#d3cbe6",
            //   borderRadius: "0.3rem",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                height: "1rem",
                "& > div": {
                //   border: "1px solid white",
                },
              }}
            >
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    lineHeight: "0.7rem",
                    // fontWeight: "bold",
                    color: "#A6A6A6",
                  }}
                >
                  P-1
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    lineHeight: "0.7rem",
                    // fontWeight: "bold",
                    color: "#A6A6A6",
                  }}
                >
                  P
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                height: "1rem",
                "& > div": {
                //   border: "1px solid white", // Añade un borde blanco a todos los div dentro de este Grid item
                },
              }}
            >
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.65rem",
                    lineHeight: "0.65rem",
                    // fontWeight: "bold",
                    color: "#674EA7",
                  }}
                >
                  Unidades
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    lineHeight: "0.7rem",
                    // fontWeight: "bold",
                    color: "#674EA7",
                  }}
                >
                  {numeral(
                    previousAndActualPeriodMetrics.totalPreviousPeriodUnits
                  ).format(
                    previousAndActualPeriodMetrics.totalPreviousPeriodUnits >=
                      10000
                      ? previousAndActualPeriodMetrics.totalPreviousPeriodUnits %
                          1 ===
                        0
                        ? "0a"
                        : "0.[0]a"
                      : null
                  )}u
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    lineHeight: "0.7rem",
                    // fontWeight: "bold",
                    color: "#674EA7",
                  }}
                >
                  {numeral(
                    previousAndActualPeriodMetrics.totalCurrentPeriodUnits
                  ).format(
                    previousAndActualPeriodMetrics.totalCurrentPeriodUnits >=
                      10000
                      ? previousAndActualPeriodMetrics.totalCurrentPeriodUnits %
                          1 ===
                        0
                        ? "0a"
                        : "0.[0]a"
                      : null
                  )}u
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default IncrementalUnits;
