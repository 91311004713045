import { Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as ArrowMediumRed } from "../../assets/images/arrow-red-big.svg";
import { ReactComponent as ArrowMediumGreen } from "../../assets/images/arrow-green-big.svg";
import { ReactComponent as ExperimentsIcon } from "../../assets/images/experiments-icon.svg";
import { ReactComponent as VendingMachineIcon } from "../../assets/images/vending-icon.svg";
import { ReactComponent as ChangesIcon } from "../../assets/images/changes-icon.svg";

import React from "react";

const MediumCardVariantA = ({ type, title, list, icon, variant, arrow }) => {
  const icons = {
    none: null,
    experiment: <ExperimentsIcon style={{ fill: "black", height: "1.5rem" }} />,
    vending: <VendingMachineIcon style={{ fill: "black", height: "1.5rem" }} />,
    blocks: <ChangesIcon style={{ fill: "black", height: "1.5rem" }} />,
  };

  const variants = {
    positive: {
      arrow: <ArrowMediumGreen />,
      color: "#00A87C",
    },
    negative: {
      arrow: <ArrowMediumRed />,
      color: "#FF0707",
    },
    neutral: {
      arrow: null,
      color: "#000",
    },
  };
  return (
    <Grid container>
      <Grid
        item
        sx={{
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          height: "8rem",
          width: "350px",
        }}
        xs={12}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100%",
            // border: "2px solid #727272",
            borderRadius: "1rem",
            paddingInlineEnd: "0.2rem",
            paddingInlineStart: "0.2rem",
            paddingBlock: "0.5rem",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", maxHeight: 1 / 3, height: 1 / 3 }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={1}
            >
              {icons[icon]}
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={11}>
              <Typography
                sx={{
                  color: variants[variant].color,
                  fontWeight: "bold",
                }}
              >
                {type}
              </Typography>
              <Typography>&nbsp;- {title}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", height: 2 / 3, maxHeight: 2 / 3 }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={1}
            >
              {arrow !== false && variants[variant].arrow}
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={11}>
              {list && Array.isArray(list) && list?.length > 0 && (
                <ol
                  style={{
                    paddingLeft: "0rem",
                    fontSize: "0.9rem",
                    width: "100%",
                    listStyleType: "none",
                  }}
                >
                  {list?.map((element, index) => (
                    <li
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                      key={index}
                    >
                      <Grid item xs={12} key={index} sx={{ display: "flex" }}>
                        <Grid
                          item
                          xs={9}
                          sx={{ fontWeight: "bold", display: "flex" }}
                        >
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {element?.name &&
                              element.name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {element?.total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                </ol>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MediumCardVariantA;
