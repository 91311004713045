import React from "react";
import { Grid, Typography } from "@mui/material";
// import { ReactComponent as MoneyLossIcon } from "../../../../assets/images/money-loss-icon.svg";
// import { ReactComponent as CircularArrowsIcon } from "../../../../assets/images/circular-arrows-icon.svg";
import { ReactComponent as CalendarAlertIcon } from "../../../../assets/images/calendar-alert-icon.svg";
import { ReactComponent as VendingMachineIcon } from "../../../../assets/images/vending-icon.svg";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const MiddleSectionProblem = ({ problem, product, store }) => {
  const capitalizeWords = (str) => {
    const lowerCaseStr = str.toLowerCase();
    const words = lowerCaseStr.split(" ");
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(" ");
  };

  function cleanString(text) {
    var pattern = /\*/g;
    var cleanedText = text.replace(pattern, "");
    return cleanedText;
  }

  return (
    <>
      <Grid item sx={{ height: 1 / 4, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          {/* <ExperimentsIcon style={{ width: "1.3rem" }} /> */}
          {/* <CircularArrowsIcon style={{ height: "1.2rem", width: "1.2rem" }} /> */}
          <WarningRoundedIcon />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              marginInlineStart: "0.2rem",
            }}
          >
            Baja Rotación de{" "}
            <span style={{ color: "#FA0909" }}>
              {capitalizeWords(product?.name)}
            </span>
            .
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ height: 1 / 4, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          {/* <CalendarMonthOutlinedIcon fontSize="medium" /> */}
          <CalendarAlertIcon style={{ height: "1.2rem", width: "1.2rem" }} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              marginInlineStart: "0.2rem",
            }}
          >
            Ventas: {problem?.unitsSold} Unidades.
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ height: 1 / 4, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          {/* <CalendarAlertIcon
                    style={{ height: "1.2rem", width: "1.2rem" }}
                  /> */}
          <VendingMachineIcon
            style={{ width: "1.3rem", fill: "#000", height: "1.3rem" }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: "0.2rem",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
            }}
          >
            {/* Ubicación: Máquina {experiment?.dStore?.storeClusterId}{" "}
            {experiment?.dStore?.storeId.slice(1)}. */}
            Ubicación: Máquina {cleanString(store?.alias)}.
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item sx={{ height: 1 / 4, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <MoneyLossIcon style={{ height: "1.2rem", width: "1.2rem" }} />
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center" }} item xs={11}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              marginInlineStart: "0.2rem",
            }}
          >
            Impacto Negativo: -20 € perdidos x dia. -100 € perdidos x semana.
          </Typography>
        </Grid>
      </Grid> */}
      {/* mid section end  */}
    </>
  );
};

export default MiddleSectionProblem;
