import React, { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Filters from "../../components/filters/filters.component";

import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import Metrics from "../../components/metrics/metrics.component";
import Footer from "../../components/footer/footer.component";
import axios from "axios";
import MediumCard from "../../components/medium-card/medium-card.component";
import {
  mediumCardDataLoserOfMonth,
  mediumCardDataNegative,
  mediumCardDataPositive,
  mediumCardDataWinnerOfMonth,
} from "../../utils/fakeData";
import AlertSmallCard from "../../components/alerts/alerts-small-card/alerts-small-card.component";
import { getUserInfo } from "../../services/userInfo.service";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const MyMachine = () => {
  const userInfo = getUserInfo();
  const [metricsData, setMetricsData] = useState({});
  const [bigCardSubtitle, setBigCardSubtitle] = useState("");
  const loginToken = localStorage.getItem("access_token");

  const [period, setPeriod] = useState("w");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [alertIdToDelete, setAlertIdToDelete] = useState(null);

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    if (filters.machines !== "default") {
      try {
        if (userInfo) {
          const { data } = await axios.get(
            `https://data.myma.space/api/machines/${filters.machines}?clientId=${userInfo.customer_id}&period=${period}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}`
          );
          setMetricsData(data);
        }
      } catch (error) {
        // Error handler
        console.error(
          "Error 500 en la solicitud de métricas de máquinas:",
          error
        );
      }
    }
  };

  const handleDeleteAlert = (alertId) => {
    const apiUrl = `https://data.myma.space/api/notifications/${alertId}/archive?clientId=${userInfo.customer_id}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (userInfo) {
      axios
        .post(apiUrl, {}, config)
        .then((response) => {
          // console.log("Alert archived successfully:", response.data);
          setConfirmDeleteDialogOpen(false);
          fetchMetrics();
        })
        .catch((error) => {
          // console.error("Error archieving alert:", error);
        });
    }
  };

  const sliderSettings = {
    spaceBetween: -15,
    slidesPerView: 1.06,
  };

  // HARDCODED FAKE DATA //
  const bigCardFirstSlider = {
    title: "Ingresos",
    subtitle: bigCardSubtitle,
    headline: "Ingresos Brutos",
  };
  const bigCardSecondSlider = {
    title: "Unidades",
    subtitle: bigCardSubtitle,
    headline: "Unidades",
  };
  const bigCardThirdSlider = {
    title: "Beneficios",
    subtitle: bigCardSubtitle,
    headline: "Beneficios Brutos",
  };

  // END OF HARDCODED FAKE DATA //

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [period, filters, loginToken]
  );

  return (
    <PullToRefreshContainer onRefresh={fetchMetrics}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Máquinas</div>
          </div>
        }
        avatar="FB"
      />
      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={() => setConfirmDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirmar eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro de que deseas eliminar ésta notificación?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDeleteDialogOpen(false)}
              color="purple"
            >
              Cancelar
            </Button>
            <Button
              onClick={async () => {
                handleDeleteAlert(alertIdToDelete);
              }}
              color="purple"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Filters
          setBigCardSubtitle={setBigCardSubtitle}
          filters={filters}
          setFilters={setFilters}
        />
        <Swiper {...sliderSettings}>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={bigCardFirstSlider}
              metricsData={metricsData}
              logo="trendlinechart"
              slideIndex="0"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={bigCardSecondSlider}
              metricsData={metricsData}
              logo="shopcart"
              slideIndex="1"
            />
          </SwiperSlide>
          <SwiperSlide>
            <BigCard
              period={period}
              setPeriod={setPeriod}
              bigCardData={bigCardThirdSlider}
              metricsData={metricsData}
              logo="trendlinechart"
              slideIndex="2"
            />
          </SwiperSlide>
        </Swiper>

        <Section title="KPIS Relevantes" />
        <Swiper {...sliderSettings}>
          <SwiperSlide>
            <Metrics slideIndex="0" metricsData={metricsData} />
          </SwiperSlide>
          <SwiperSlide>
            <Metrics slideIndex="1" metricsData={metricsData} />
          </SwiperSlide>
        </Swiper>
        {metricsData?.notifications?.length !== 0 && (
          <Section title="Notificaciones" />
        )}
        <Swiper {...sliderSettings}>
          {metricsData?.notifications
            ?.slice()
            .reverse()
            .map((element) => {
              if (!element.archived) {
                return (
                  <SwiperSlide>
                    <AlertSmallCard
                      key={element.id}
                      setConfirmDeleteDialogOpen={setConfirmDeleteDialogOpen}
                      setAlertIdToDelete={setAlertIdToDelete}
                      {...element}
                    />
                  </SwiperSlide>
                );
              } else {
                return null;
              }
            })}
        </Swiper>
        {metricsData?.insights?.[0]?.insights?.length === 3 && (
          <>
            <Section title="Insights" />
            <Swiper {...sliderSettings}>
              <SwiperSlide>
                <MediumCard
                  variant={"a"}
                  data={{
                    ...mediumCardDataPositive,
                    list: metricsData?.insights?.[0]?.insights,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <MediumCard
                  variant={"d"}
                  data={{
                    ...mediumCardDataWinnerOfMonth,
                    product: metricsData?.insights?.[0]?.insights?.[0]?.name,
                    total: metricsData?.insights?.[0]?.insights?.[0]?.total,
                  }}
                />
              </SwiperSlide>
            </Swiper>
            <Swiper {...sliderSettings}>
              <SwiperSlide>
                <MediumCard
                  variant={"a"}
                  data={{
                    ...mediumCardDataNegative,
                    list: metricsData?.insights?.[1]?.insights,
                  }}
                />
              </SwiperSlide>

              <SwiperSlide>
                <MediumCard
                  variant={"d"}
                  data={{
                    ...mediumCardDataLoserOfMonth,
                    product: metricsData?.insights?.[1]?.insights?.[2]?.name,
                    total: metricsData?.insights?.[1]?.insights?.[2]?.total,
                  }}
                />
              </SwiperSlide>
            </Swiper>
          </>
        )}
        {/* <Section title="Campañas" />
        <Slider {...sliderSettings}>
          <MediumCard variant={"c"} data={mediumCardDataNeutral} />
          <MediumCard variant={"c"} data={mediumCardDataNeutral} />
        </Slider> */}
      </Box>
      <Footer activeIconID={1} />
    </PullToRefreshContainer>
  );
};

export default MyMachine;
