import { useState, useEffect } from "react";
import "moment/locale/es";
import moment from "moment-timezone";

function FormatPeriod({ period, startDate, endDate }) {
  const [previousStartDate, setPreviousStartDate] = useState(null);
  const [renderedMoment, setRenderedMoment] = useState(null);

  const formatDatesRange = (date) => {
    if (!date || !(date instanceof Date)) return "-/-";

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");

    return `${day}/${month}`;
  };

  useEffect(() => {
    setPreviousStartDate(startDate);
  }, [startDate]);

  const shouldRenderMoment = () => {
    return startDate !== previousStartDate;
  };

  useEffect(
    () => {
      // If the new startDate is different from the old startDate, render the Moment Component
      if (shouldRenderMoment()) {
        setRenderedMoment(getFormat(period));
      }
    },
    // eslint-disable-next-line
    [startDate]
  );

  const getFormat = (period) => {
    const daysName = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];
    const monthsName = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    let startDateEurope = moment(startDate).add(6, "hours").toDate();
    // let endDateEurope = moment(endDate).add(6, "hours").toDate();
    let endDateEurope = moment(endDate)
      .subtract({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
      .add(6, "hours")
      .toDate();

    const dayOfWeek = (date) => moment(date).isoWeekday();
    const dayOfMonth = (date) => moment(date).date();
    const month = (date) => moment(date).month();
    const year = (date) => moment(date).year();
    // const weekNumber = (date) => moment(date).isoWeek();

    switch (period) {
      case "d":
        return `${daysName[dayOfWeek(startDateEurope) - 1]}, ${dayOfMonth(
          startDateEurope
        )} de ${monthsName[month(startDateEurope)]} de ${year(
          startDateEurope
        )}`;
      case "w":
        // return `Semana ${weekNumber(startDateEurope) + 1} del año ${year(startDateEurope)}`
        return `Dom. ${formatDatesRange(
          startDateEurope
        )} al Sáb. ${formatDatesRange(endDateEurope)} de ${year(
          startDateEurope
        )}`;
      case "m":
        return `${monthsName[month(startDateEurope)]} de ${year(
          startDateEurope
        )}`;
      case "y":
        return `Año ${year(startDateEurope)}`;
      default:
        return "";
    }
  };

  return renderedMoment;
}

export default FormatPeriod;
