import React, { useState } from "react";
import { AppBar, Avatar, Grid, styled, Typography } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useNavigate } from "react-router-dom";
import LogoutDialog from "./logout-dialog.component";

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.purple.main,
  padding: "15px 44px",
  display: "flex",
}));

function getInitials(str) {
  if (!str) {
    return "OP";
  }
  let words = str.split(" ");
  let initials = [];
  for (let word of words) {
    let initial = word.charAt(0).toUpperCase();
    initials.push(initial);
  }
  return initials.join("");
}

const Header = ({ title, longTitle, disableCart }) => {
  const name = JSON.parse(localStorage.getItem("userInfo"))?.name;
  const avatar = getInitials(name);
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  return (
    <HeaderContainer position="static">
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", position: "relative" }}>
          <Typography
            variant={longTitle ? "h6" : "h5"}
            textAlign={"center"}
            color="#FFF"
            sx={{ width: "100%", fontWeight: 800 }}
          >
            {title}
          </Typography>
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: -44,
              top: "50%",
              transform: "translateY(-50%)",
              marginInlineEnd: "0.5rem",
            }}
          >
            {!disableCart && (
              <div className="shoppCart">
                <ShoppingCartOutlinedIcon
                  onClick={() => navigate("/sales")}
                  sx={{
                    marginInlineEnd: "0.5rem",
                    width: 40,
                    height: 40,
                    border: "1px solid #FFF",
                    borderRadius: "50%",
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.3rem",
                    fill: "#663b73",
                    opacity: "85%",
                  }}
                />
              </div>
            )}
            <div>
              <Avatar
                onClick={() => setOpenLogoutDialog(true)}
                sx={{
                  border: "1px solid #FFF",
                  backgroundColor: "#D9D9D9",
                  color: "#000",
                  fontWeight: "bold",
                  opacity: "85%",
                  cursor: "pointer",
                }}
              >
                {avatar ?? "OP"}
              </Avatar>
            </div>
          </div>
        </Grid>
      </Grid>
      <LogoutDialog
        open={openLogoutDialog}
        handleClose={() => setOpenLogoutDialog(false)}
        handleLogout={handleLogout}
      />
    </HeaderContainer>
  );
};

export default Header;
