import { Fade, Grid, Typography } from "@mui/material";
import { ReactComponent as SplashBG } from "../../assets/images/splash-bg.svg";
import { ReactComponent as MymaLogo } from "../../assets/images/myma-logo.svg";
import ContainerScreen from "../../styled-components/container-screen";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../services/utils";
import axios from "axios";

const SplashScreen = ({ splash, setOpenExpiredSessionModal }) => {
  const navigate = useNavigate();

  useEffect(
    () => {
      const verifyToken = async () => {
        try {
          const token = localStorage.getItem("access_token");
          if (!token) {
            localStorage.removeItem("userInfo");
            navigate("/login");
            return;
          }
          await axios.get("https://data.myma.space/api/auth/userinfo", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setAuthToken(token);
        } catch (error) {
          // localStorage.removeItem("access_token");
          // localStorage.removeItem("userInfo");
          // navigate("/login");
          setOpenExpiredSessionModal(true);
        }
      };

      verifyToken();
    },
    // eslint-disable-next-line
    [navigate]
  );
  return (
    <Fade in={splash}>
      <ContainerScreen bgColor="purple">
        <Grid container>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <SplashBG width={"85%"} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <MymaLogo />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="overline"
              display="block"
              align="center"
              color={"white"}
            >
              V1.0
            </Typography>
          </Grid>
        </Grid>
      </ContainerScreen>
    </Fade>
  );
};

export default SplashScreen;
