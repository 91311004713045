import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as ColorDotIcon } from "../../assets/images/traffic-light-dot.svg";
import ReactCardFlip from "react-card-flip";
import numeral from "numeral";

const TrafficLight = ({
  position,
  expectedTotalUnitsSold,
  expectedTotalUnitsSoldStr,
  totalUnitsSold,
  totalUnitsSoldStr,
  expectedTotalSales,
  expectedTotalSalesStr,
  totalSales,
  totalSalesStr,
  expectedTotalBenefits,
  totalBenefits,
  colorBenefits,
  colorSales,
  colorUnits,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const colors = {
    red: "#fa0909",
    green: "#00a87c",
    yellow: "#e6a800",
  };

  const CardFront = () => {
    return (
      <div style={{ padding: 0, boxShadow: "none" }}>
        <Grid onClick={() => setIsFlipped(!isFlipped)} container>
          <Grid
            item
            xs={12}
            sx={{
              marginInline: "1rem",
              marginBlockStart: "0.5rem",
              display: "flex",
              width: "350px",
              paddingInlineEnd: "0.5rem",
              paddingInlineStart: "0.2rem",
              backgroundColor: "#F6F6F6",
              height: "2.5rem",
              boxShadow: "0 4px 4px 0 rgba(0,0,0,.2)",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
              item
              xs={5}
            >
              <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                {position}
              </Typography>
            </Grid>

            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorUnits],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorUnits] }} />
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorSales],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorSales] }} />
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorBenefits],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorBenefits] }} />
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
    );
  };
  const CardBack = () => {
    return (
      <div style={{ padding: 0, boxShadow: "none" }}>
        <Grid onClick={() => setIsFlipped(!isFlipped)} container>
          <Grid
            item
            xs={12}
            sx={{
              marginInline: "1rem",
              marginBlockStart: "0.5rem",
              display: "flex",
              width: "350px",
              paddingInlineEnd: "0.5rem",
              paddingInlineStart: "0.2rem",
              backgroundColor: "#F2F2F2",
              height: "2.5rem",
              boxShadow: "0 4px 4px 0 rgba(0,0,0,.2)",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
              item
              xs={5}
            >
              <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                {position}
              </Typography>
            </Grid>

            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "bold",
                    color: colors[colorUnits],
                  }}
                >
                  {`${totalUnitsSoldStr}/${expectedTotalUnitsSoldStr}`}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "bold",
                    color: colors[colorSales],
                  }}
                >
                  {`${totalSalesStr}/${expectedTotalSalesStr} €`}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.6rem",
                    fontWeight: "bold",
                    color: colors[colorBenefits],
                  }}
                >
                  {`${numeral(totalBenefits)
                    .format(totalBenefits % 1 === 0 ? "0a" : "0.[0]a")
                    .replace(".", ",")}/${numeral(expectedTotalBenefits)
                    .format(expectedTotalBenefits % 1 === 0 ? "0a" : "0.[0]a")
                    .replace(".", ",")} %`}
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <CardFront />
      <CardBack />
    </ReactCardFlip>
  );
};

export default TrafficLight;
