import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home.component";
import Layout from "./components/layout/layout.component";
import MyMachine from "./pages/my-machine/my-machine.component";
import MyExperiments from "./pages/my-experiments/my-experiments.component";
import Alerts from "./pages/alerts/alerts.component";
import Insights from "./pages/insights/insights.component";
import Sales from "./pages/sales/sales.component";
import Login from "./pages/login/login.component";
import Promos from "./pages/promos/promos.component";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="machines" element={<MyMachine />} />
      <Route path="experiments" element={<MyExperiments />} />
      <Route path="alerts" element={<Alerts />} />
      <Route path="insights" element={<Insights />} />
      <Route path="sales" element={<Sales />} />
      <Route path="login" element={<Login />} />
    </Route>

    <Route path="/promos" element={<Promos />} />
  </Routes>
);

export default AppRoutes;
