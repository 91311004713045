import React from "react";
import { Button } from "@mui/material";
import HistoryToggleOffRoundedIcon from "@mui/icons-material/HistoryToggleOffRounded";
const IncomingButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        height: "2.5rem",
        width: "auto",
        paddingInline: "0.7rem",
        border: "1px solid #b6b3af",
        borderRadius: "1.2rem",
        display: "flex",
        textTransform: "none",
        fontSize: "0.85rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#fff",
        backgroundColor: "#b6b3af",
        fontStyle: "italic",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: "#b6b3af",
          color: "#fff",
          border: "1px solid #b6b3af",
        },
        "& .endIcon": {
          fill: "#fff",
        },
      }}
      endIcon={
        <HistoryToggleOffRoundedIcon
          style={{ height: "20px", width: "20px" }}
        />
      }
    >
      Próximamente
    </Button>
  );
};

export default IncomingButton;
