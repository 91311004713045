import { InputBase, InputLabel, styled } from "@mui/material";

const Input = styled(InputBase)((props) => {
  return {
    backgroundColor: "#fff",
    color: "#663b73",
    fontSize: "15px",
    paddingInline: "30px",
    borderRadius: "2rem",
    width: "100%",
    height: "64px",
    marginBottom: 10,
    disabled: props.disabled,
    transition: "border-color 0.3s, box-shadow 0.3s",

    "&.Mui-focused": {
      boxShadow: `0px 0px 0px 0.3rem rgba(192, 154, 203, 0.5)`,
    },
    "& input::placeholder": {
      fontStyle: "italic",
      color: "rgb(133, 133, 133)",
    },
  };
});

export const CustomInput = (props) => {
  return (
    <>
      <InputLabel
        sx={{
          color: "#FFF",
          marginBottom: "5px",
          overflow: props.name === "identificationType" && "visible",
        }}
      >
        {props?.label}
      </InputLabel>
      <Input {...props} />
    </>
  );
};

export default CustomInput;
