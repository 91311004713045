import React from "react";
import { AppBar, Grid, styled, Typography } from "@mui/material";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary,
  padding: "15px 0px",
  display: "flex",
}));

const Header = ({ title }) => {
  return (
    <HeaderContainer position="static">
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              fontSize={"1.1rem"}
              color="#FFF"
              sx={{
                width: "100%",
                fontWeight: 800,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex" }}>
            <CampaignRoundedIcon fontSize="large" />
          </Grid>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default Header;
