import React from "react";
import {
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ReactComponent as TrendLineChartIcon } from "../../assets/images/trend-line-chart.svg";
import { ReactComponent as ArrowBigGreen } from "../../assets/images/arrow-green-big.svg";
import { ReactComponent as ArrowBigRed } from "../../assets/images/arrow-red-big.svg";
import { ReactComponent as ExperimentsIcon } from "../../assets/images/experiments-icon.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";
import ProgressBar from "@ramonak/react-progress-bar";
import FormatPeriod from "../../utils/formatPeriod";
import numeral from "numeral";

//numeral library locale configuration
numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "º";
  },
  currency: {
    symbol: "€",
  },
});

numeral.locale("es");
//

const BigCard = ({
  metricsData,
  bigCardData,
  period,
  setPeriod,
  logo,
  experimentsPage,
  sprintProgress,
  slideIndex,
}) => {
  const slideIndexToCurrencyContent = {
    0: metricsData?.currency?.symbol,
    1: "u",
    2: metricsData?.currency?.symbol,
  };

  // ?? (nullish coalescing operator ECMAScript 2020)
  const currencyContent = slideIndexToCurrencyContent[slideIndex] ?? null;

  const percentage = Math.round(
    parseFloat(metricsData?.metrics?.[slideIndex]?.differenceStr)
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "1rem",
          marginBlockStart: "0.5rem",
          marginBlockEnd: "0.5rem",
          display: "flex",
          flexDirection: "column",
          width: "350px",
          height: "auto",
        }}
      >
        <Paper sx={{ borderRadius: "10px" }} elevation={5}>
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "89px",
              // backgroundColor: "#663B73",
              backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
              borderRadius: "10px 10px 0px 0px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#FCFCF7",
                fontSize: "24px",
              }}
            >
              {bigCardData.title}
            </Typography>
            <Typography
              sx={{
                fontWeight: "normal",
                color: "#A9A9A9",
                fontSize: "18px",
              }}
            >
              {bigCardData.subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "207px",
              padding: "32px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                minHeight: "2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {logo === "trendlinechart" && (
                  <TrendLineChartIcon style={{ height: "32px" }} />
                )}
                {logo === "shopcart" && (
                  <ShoppingCartIcon sx={{ fontSize: "32px" }} />
                )}
                {logo === "experiment" && (
                  <ExperimentsIcon style={{ width: "23px" }} />
                )}
              </Grid>
              <Grid
                item
                //xs={experimentsPage ? 10 : 8}
                xs={8}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Typography sx={{ fontSize: "1.25rem" }}> */}
                <Typography noWrap sx={{ fontSize: "1.25rem" }}>
                  {bigCardData.headline}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                minHeight: "4rem",
                display: "flex",
              }}
            >
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {metricsData?.metrics?.[slideIndex]?.difference < 0 ? (
                  <ArrowBigRed />
                ) : (
                  <ArrowBigGreen />
                )}
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Typography
                  variant="h3"
                  noWrap
                  sx={{
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {numeral(metricsData?.metrics?.[slideIndex]?.total).format(
                    metricsData?.metrics?.[slideIndex]?.total >= 10000
                      ? metricsData?.metrics?.[slideIndex]?.total % 1 === 0
                        ? "0a"
                        : "0.[0]a"
                      : null
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.8rem",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  {currencyContent}
                </Typography>
              </Grid>
              {!experimentsPage && (
                <Grid
                  item
                  xs={2}
                  sx={{
                    color: "#fff",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      backgroundColor: `${
                        metricsData?.metrics?.[slideIndex]?.difference < 0
                          ? "#FD3F03"
                          : "#2CAF5F"
                      }`,
                      borderRadius: "3px",
                      padding: "5px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    {`${numeral(percentage).format("0a")}%`}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                minHeight: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              {sprintProgress && (
                <ProgressBar
                  bgColor="#663B73"
                  width="10rem"
                  completed={sprintProgress}
                />
              )}
              {period && (
                <ToggleButtonGroup
                  color="black"
                  sx={{ height: "25px" }}
                  value={period}
                  onChange={(event, newValue) => {
                    // Verificar si el nuevo valor es diferente al valor actual o no es null
                    if (newValue !== period && newValue !== null) {
                      setPeriod(newValue);
                    }
                  }}
                  exclusive
                  size="small"
                >
                  <ToggleButton value="d" aria-label="day">
                    D
                  </ToggleButton>
                  <ToggleButton value="w" aria-label="week">
                    S
                  </ToggleButton>
                  <ToggleButton value="m" aria-label="month">
                    M
                  </ToggleButton>
                  <ToggleButton value="y" aria-label="year">
                    A
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              minHeight: "35px",
              backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
              borderRadius: "0px 0px 10px 10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {metricsData?.period?.current?.startDate && (
              <Typography color="#A9A9A9">
                <FormatPeriod
                  period={period}
                  startDate={metricsData.period.current.startDate}
                  endDate={metricsData.period.current.endDate}
                />
              </Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BigCard;
