export function filterExperimentsByDateRange(experiments, dateFrom, dateTo) {
  return experiments.filter((experiment) => {
    const start = new Date(experiment.period.start);
    const end = new Date(experiment.period.end);
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);

    return start >= fromDate && end <= toDate;
  });
}

export function calculateIncrements(arr) {
  let incrementalUnits = 0;
  let incrementalRevenue = 0;
  //Para calcular las unidades incrementales hace la diferencia entre las unidades vendidas del experimento y las unidades vendidas del problema.
  //Suma éste resultado en la variable incrementalUnits iterando en cada elemento del arreglo.
  arr.forEach((item) => {
    const difference =
      item?.status &&
      item?.status?.unitsSold - (item?.problem && item?.problem?.unitsSold);

    incrementalUnits += difference;

    //Para calcular la facturacion incremental multiplica las unidades incrementales de cada elemento del arreglo por su avgPrice.
    //Suma éste resultado en la variable IncrementalRevenue iterando en cada elemento del arreglo.
    if (item?.status && item?.status?.avgPrice) {
      incrementalRevenue += difference * item?.status?.avgPrice;
    }
  });

  return { incrementalUnits, incrementalRevenue };
}

export function calculateIncrementalPercentages(arr) {
  let totalUnitsPercentage = 0;
  let totalRevenuePercentage = 0;

  arr.forEach((item) => {
    // Calcula el porcentaje de unidades incrementales parcial para cada experimento
    const unitsPercentage =
      (item?.status?.unitsSold / item?.expected?.unitsSold) * 100;
    totalUnitsPercentage += unitsPercentage;

    // Calcula el porcentaje de facturación incremental parcial para cada experimento
    const revenuePercentage =
      (((item?.status?.unitsSold - item?.problem?.unitsSold) *
        item?.status?.avgPrice) /
        item?.expected?.totalSold) *
      100;
    totalRevenuePercentage += revenuePercentage;
  });

  // Calcula el promedio de los porcentajes de unidades incrementales
  const averageUnitsPercentage = totalUnitsPercentage / arr.length;

  // Calcula el promedio de los porcentajes de facturación incremental
  const averageRevenuePercentage = totalRevenuePercentage / arr.length;

  return { averageUnitsPercentage, averageRevenuePercentage };
}

export function calculatePreviousAndActualPeriodMetrics(arr) {
  let totalPreviousPeriodUnits = 0;
  let totalCurrentPeriodUnits = 0;
  let totalPreviousPeriodRevenue = 0;
  let totalCurrentPeriodRevenue = 0;

  arr.forEach((item) => {
    // Suma las unidades vendidas del periodo anterior (problema)
    totalPreviousPeriodUnits += item?.problem?.unitsSold || 0;

    // Suma las unidades vendidas del periodo actual (experimento)
    totalCurrentPeriodUnits += item?.status?.unitsSold || 0;

    // Calcula la facturación del periodo anterior (problema)
    const previousPeriodRevenue =
      (item?.problem?.unitsSold || 0) * (item?.status?.avgPrice || 0);
    totalPreviousPeriodRevenue += previousPeriodRevenue;

    // Calcula la facturación del periodo actual (experimento)
    const currentPeriodRevenue =
      (item?.status?.unitsSold || 0) * (item?.status?.avgPrice || 0);
    totalCurrentPeriodRevenue += currentPeriodRevenue;
  });

  return {
    totalPreviousPeriodUnits,
    totalCurrentPeriodUnits,
    totalPreviousPeriodRevenue,
    totalCurrentPeriodRevenue,
  };
}
