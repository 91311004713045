const capitalizeWords = (str) => {
  const lowerCaseStr = str.toLowerCase();
  const words = lowerCaseStr.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
};

export const experimentsEquivalency = {
  promo_precio_unitario: {
    shortLabel: "Promo Prec. Unit.",
    fullLabel: "Promo Precio Unitario",
    actionDescription: ({
      productName,
      discountPercentage,
      price,
      newPrice,
    }) => (
      <>
        <span style={{ fontWeight: "bold" }}>Promo Precio </span>de{" "}
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(productName)}
        </span>{" "}
        un <span style={{ fontWeight: "bold" }}>{discountPercentage}</span>{" "}
        <span style={{ fontWeight: "bold" }}>off</span>, de{" "}
        <span style={{ fontWeight: "bold", color: "#FA0909" }}>{price}€</span>{" "}
        <span style={{ fontWeight: "bold" }}>a </span>
        <span style={{ fontWeight: "bold", color: "#00A87C" }}>
          {newPrice}€
        </span>
        .
      </>
    ),
    runningDescription: ({
      productName,
      discountPercentage,
      price,
      newPrice,
    }) => (
      <>
        Precio{" "}
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(productName)}
        </span>{" "}
        un <span style={{ fontWeight: "bold" }}>{discountPercentage}</span> off,
        de <span style={{ fontWeight: "bold" }}>{price}€</span> a{" "}
        <span style={{ fontWeight: "bold" }}>{newPrice}€</span>.
      </>
    ),
  },
  promo_combo_simple: {
    shortLabel: "Promo",
    fullLabel: "Promo Combo Simple",
    actionDescription: "Próximamente...",
    runningDescription: "Próximamente...",
  },
  promo_combo_combinado: {
    shortLabel: "Prom. Combo Combinado",
    fullLabel: "Promo Combo Combinado",
    actionDescription: ({
      firstProduct,
      secondProduct,
      comboPrice,
      discountPercentage,
    }) => (
      <>
        Crear <span style={{ fontWeight: "bold" }}>Combo </span>
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(firstProduct)}
        </span>
        <span style={{ fontWeight: "bold" }}> + </span>
        <span style={{ fontWeight: "bold" }}>{secondProduct}</span>{" "}
        {!isNaN(parseFloat(discountPercentage)) &&
        parseFloat(discountPercentage) !== 0 ? (
          <>
            a un valor conjunto de{" "}
            <span style={{ fontWeight: "bold" }}>
              €
              {(
                parseFloat(comboPrice) -
                ((parseFloat(discountPercentage) * comboPrice) / 100).toFixed(1)
              ).toLocaleString("es-ES")}
            </span>{" "}
            (PL €{comboPrice.toLocaleString("es-ES")})
          </>
        ) : (
          <>
            a un valor conjunto de{" "}
            <span style={{ fontWeight: "bold" }}>€{comboPrice}</span>
          </>
        )}
      </>
    ),
    runningDescription: ({
      firstProduct,
      secondProduct,
      comboPrice,
      discountPercentage,
    }) => (
      <>
        <span style={{ fontWeight: "bold" }}>Combo </span>
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(firstProduct)}
        </span>
        <span style={{ fontWeight: "bold" }}> + </span>
        <span style={{ fontWeight: "bold" }}>{secondProduct}</span>
        {!isNaN(parseFloat(discountPercentage)) &&
        parseFloat(discountPercentage) !== 0 ? (
          <>
            a un valor conjunto de{" "}
            <span style={{ fontWeight: "bold" }}>
              €
              {(
                parseFloat(comboPrice) -
                ((parseFloat(discountPercentage) * comboPrice) / 100).toFixed(1)
              ).toLocaleString("es-ES")}
            </span>{" "}
            (PL €{comboPrice.toLocaleString("es-ES")})
          </>
        ) : (
          <>
            a un valor conjunto de{" "}
            <span style={{ fontWeight: "bold" }}>€{comboPrice}</span>
          </>
        )}
      </>
    ),
  },
  reemplazo_producto: {
    shortLabel: "Reemplazo Prod.",
    fullLabel: "Reemplazo Producto",
    actionDescription: ({
      productName,
      productIdealStock,
      newProductName,
      newProductIdealStock,
    }) => (
      <>
        <span style={{ fontWeight: "bold" }}>Retirar </span>
        <span style={{ fontWeight: "bold", color: "#FA0909" }}>
          {capitalizeWords(productName)}
        </span>{" "}
        en <span style={{ fontWeight: "bold" }}>Unidades: </span>
        <span style={{ fontWeight: "bold", color: "#FA0909" }}>
          {productIdealStock}
        </span>
        ,
        <br /> <span style={{ fontWeight: "bold" }}>Agregar </span>
        <span style={{ fontWeight: "bold", color: "#00A87C" }}>
          {capitalizeWords(newProductName)}
        </span>{" "}
        en <span style={{ fontWeight: "bold" }}>Unidades: </span>
        <span style={{ fontWeight: "bold", color: "#00A87C" }}>
          {newProductIdealStock}
        </span>
        .
      </>
    ),
    runningDescription: ({ productName, newProductName }) => (
      <>
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(productName)}
        </span>{" "}
        por{" "}
        <span style={{ fontWeight: "bold" }}>
          {capitalizeWords(newProductName)}
        </span>
      </>
    ),
  },
  ajuste_planograma: {
    shortLabel: "Ajuste",
    fullLabel: "Ajuste Planograma",
    actionDescription: "Próximamente...",
    runningDescription: "Próximamente...",
  },
  baja_precio_sugerido: {
    shortLabel: "Baja Precio",
    fullLabel: "Baja Precio Sugerido",
    actionDescription: "Próximamente...",
    runningDescription: "Próximamente...",
  },
  alza_precio_sugerido: {
    shortLabel: "Alza Precio",
    fullLabel: "Alza Precio Sugerido",
    actionDescription: "Próximamente...",
    runningDescription: "Próximamente...",
  },
};
