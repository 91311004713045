import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PullToRefresh from "react-simple-pull-to-refresh";

export const PullingElement = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "90px",
        color: "#663B73",
        fontWeight: "bold",
        marginBlockStart: "10px",
      }}
    >
      Desliza para refrescar las Métricas.
    </div>
  );
};

export const RefreshingElement = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90px",
      }}
    >
      <CircularProgress color="purple" />
    </div>
  );
};

export const PullToRefreshContainer = ({ children, onRefresh }) => {
  const defaultPullToRefreshProps = {
    refreshingContent: <RefreshingElement />,
    maxPullDownDistance: 120,
    pullDownThreshold: 90,
    pullingContent: <PullingElement />,
    resistance: 4,
  };

  return (
    <PullToRefresh onRefresh={onRefresh} {...defaultPullToRefreshProps}>
      {children}
    </PullToRefresh>
  );
};
