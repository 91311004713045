import React, { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import { Box, Grid } from "@mui/material";
import Filters from "../../components/filters/filters.component";
import Footer from "../../components/footer/footer.component";
import { PullToRefreshContainer } from "../../utils/PullToRefreshElements";
import moment from "moment-timezone";
import { getUserInfo } from "../../services/userInfo.service";
import axios from "axios";
import Section from "../../components/section/section.component";
import MetricsImpact from "../../components/insights/metrics-impact/metrics-impact.component";
import {
  calculateIncrementalPercentages,
  calculateIncrements,
  calculatePreviousAndActualPeriodMetrics,
  filterExperimentsByDateRange,
} from "../../components/insights/metricsCalculations";

const Insights = () => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const currentDate = moment().format("YYYY-MM-DD");
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const [dateFrom, setDateFrom] = useState(firstDayOfMonth);
  const [dateTo, setDateTo] = useState(currentDate);

  const [finishedExperiments, setFinishedExperiments] = useState([]);
  const [filteredExperiments, setFilteredExperiments] = useState([]);
  const [totalIncrementalUnits, setTotalIncrementalUnits] = useState("");
  const [totalIncrementalProfit, setTotalIncrementalProfit] = useState("");
  const [incrementalUnitsPercentage, setIncrementalUnitsPercentage] =
    useState("");
  const [incrementalProfitPercentage, setIncrementalProfitPercentage] =
    useState("");
  const [previousAndActualPeriodMetrics, setpreviousAndActualPeriodMetrics] =
    useState({});

  const fetchFinishedExperiments = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `https://data.myma.space/api/experiments?clientId=${userInfo.customer_id}&group=${filters.groups}&storeClusterId=${filters.clusters}&category=${filters.categories}&storeId=${filters.machines}&status=finished`
        );

        setFinishedExperiments(data.experiments);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };
  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchFinishedExperiments();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchFinishedExperiments();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  useEffect(() => {
    if (finishedExperiments) {
      setFilteredExperiments(
        filterExperimentsByDateRange(finishedExperiments, dateFrom, dateTo)
      );
    }
  }, [finishedExperiments, dateFrom, dateTo]);
  useEffect(() => {
    if (filteredExperiments) {
      let { incrementalUnits, incrementalRevenue } =
        calculateIncrements(filteredExperiments);

      let { averageUnitsPercentage, averageRevenuePercentage } =
        calculateIncrementalPercentages(filteredExperiments);

      setTotalIncrementalUnits(incrementalUnits);
      setTotalIncrementalProfit(incrementalRevenue);
      setIncrementalUnitsPercentage(averageUnitsPercentage);
      setIncrementalProfitPercentage(averageRevenuePercentage);
      setpreviousAndActualPeriodMetrics(
        calculatePreviousAndActualPeriodMetrics(filteredExperiments)
      );
    }
  }, [filteredExperiments]);

  return (
    <PullToRefreshContainer onRefresh={fetchFinishedExperiments}>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Insights</div>
          </div>
        }
        avatar="FB"
        // longTitle
      />

      <Box
        sx={{
          marginBottom: "90px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Filters
          disableFirstMachineSelected
          filters={filters}
          setFilters={setFilters}
        />
        <Grid
          container
          sx={{
            marginBlockStart: "0rem",
            marginBlockEnd:"1rem"
          }}
        >
          <Grid
            item
            sx={{
              gap: "1rem",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F8F8F8",
            }}
            xs={12}
          >
            <label htmlFor="startDate">Desde:</label>
            <input
              style={{
                border: "2px solid #663B73",
                borderRadius: "0.5rem",
                textAlign: "center",
                fontWeight: "bold",
              }}
              type="date"
              max={currentDate}
              name="startDate"
              id="startDate"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
            <label htmlFor="endDate">Hasta:</label>
            <input
              style={{
                border: "2px solid #663B73",
                borderRadius: "0.5rem",
                textAlign: "center",
                fontWeight: "bold",
              }}
              max={currentDate}
              type="date"
              name="endDate"
              id="endDate"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </Grid>
          <Section title="Resumen Métricas de Impacto" />
          <MetricsImpact
            previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
            incrementalUnitsPercentage={incrementalUnitsPercentage}
            incrementalProfitPercentage={incrementalProfitPercentage}
            totalIncrementalProfit={totalIncrementalProfit}
            totalIncrementalUnits={totalIncrementalUnits}
          />
        </Grid>
      </Box>
      <Footer activeIconID={4} />
    </PullToRefreshContainer>
  );
};

export default Insights;
