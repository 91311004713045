import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const LogoutDialog = ({ open, handleClose, handleLogout }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "#663b73" }}>Cerrar Sesión</DialogTitle>
      <DialogContent>
        <p>¿Estás seguro de que deseas cerrar sesión?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="purple">
          Cancelar
        </Button>
        <Button onClick={handleLogout} color="purple">
          Cerrar Sesión
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
